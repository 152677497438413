<template>
  <asom-alert variant="warning" v-if="!isBaseStation" title="You are not in your Resident station">
    <p class="text-sm pt-1">You are viewing station <strong>{{selectedStationName}}</strong> that is not your base station.</p>
  </asom-alert>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      'selectedStationId': "selectedStation/id",
      'selectedStationName': "selectedStation/name",
      "baseStationId": "auth/userStationId",
      "baseStationName": "auth/userStationName",
    }),
    isBaseStation() {
      return this.selectedStationId == null || this.selectedStationId === this.baseStationId;
    }
  }
}
</script>

<style>

</style>