<template>
  <div class="space-y-4">
    <not-base-station-alert />
    <router-view />
  </div>
</template>
<script>
import NotBaseStationAlert from '../../layout/NotBaseStationAlert.vue'
export default {
  components: {
    NotBaseStationAlert
  }
}
</script>